import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  FiDownload,
  FiMail,
  FiPhone,
} from 'react-icons/fi';
import {
  MediaElement,
  TypographyElement,
} from '@plugins/next-cms-core';
import { NUMBER_OF_BANKS } from 'config';
import AppointmentRequestFormBlock from '@components/contentTypes/AppointmentRequestFormBlock';
import Backdrop from '@components/atoms/Backdrop';
import Button from '@components/atoms/Button';
import Container from '@components/atoms/Container';
import CustomerTestimonialsBlock from '@components/contentTypes/CustomerTestimonialsBlock';
import DefaultTemplate from '@components/templates/DefaultTemplate';
import Error from 'next/error';
import Head from 'next/head';
import Image from '@components/atoms/Image';
import InterestCalculatorBlock from '@components/contentTypes/InterestCalculatorBlock';
import InterestRateDevelopmentBlock from '@components/contentTypes/InterestRateDevelopmentBlock';
import Link from '@components/atoms/Link';
import PageInsetBottom from '@components/atoms/PageInsetBottom';
import PageInsetTop from '@components/atoms/PageInsetTop';
import PropTypes from 'prop-types';
import { WhatsappIcon } from '@components/molecules/WhatsappFab';
import { gql } from '@apollo/client';
import { makeStyles } from '@material-ui/styles';
import { siteName } from '../../constants';
import { zIndices } from '../../theme';

export default function Consultant({ consultant }) {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  if (!consultant) {
    return (
      <Error statusCode={404} title="Berater nicht gefunden"/>
    );
  }

  if (!consultant.published_at) {
    return (
      <Error statusCode={410} title="Berater ist nicht mehr vorhanden"/>
    );
  }

  return (
    <>
      <Head>
        <title>
          {`${consultant.name} | ${siteName}`}
        </title>
        <meta name="description"
          content={`Ihr Experte für Baufinanzierungen, Bausparen und Bauförderung. Kontaktdaten, Bewertungen und Online-Terminvereinbarung von ${consultant.name} auf einem Blick.`}/>
      </Head>
      <DefaultTemplate className={classes.root}>
        <Header
          classes={classes}
          consultant={consultant}
        />
        <AppointmentRequestFormBlock data={{
          targetEmail: consultant.email,
          directAppointmentUrl: consultant.action_appointment,
          leadOwner: consultant.id,
        }}/>
        <Box mt={10} pb={5}>
          <Container>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Contact
                  classes={classes}
                  consultant={consultant}
                />
              </Grid>
              <Grid item md={8}>
                <Box mt={isXs ? 10 : 0} ml={!isXs ? 10 : 0}>
                  <Details
                    classes={classes}
                    consultant={consultant}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
        {consultant.isFinancingRequestFormVisible && (
          <Box style={{ background: 'white' }}>
            <InterestCalculatorBlock data={{
              employee: consultant,
              channel: 'FINANCING_REQUEST_FORM_CONSULTANT',
            }}/>
          </Box>
        )}
        <Box>
          <InterestRateDevelopmentBlock data={{}}/>
        </Box>
        <Box>
          <CustomerTestimonialsBlock data={{
            employee: {
              id: consultant.id,
            },
            color_scheme: {
              color: 'light',
            },
          }}/>
        </Box>
        <PageInsetBottom/>
      </DefaultTemplate>
    </>
  );
}

function Header({ classes, consultant }) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className={classes.header}>
      <div
        className={classes.headerBackground}
      />
      <Backdrop>
        <Container>
          <PageInsetTop/>
          <Grid container alignItems="flex-end">
            <Grid item xs={12} sm={5} md={3}>
              <Box mb={isSm ? 5 : 0}>
                <Card elevation={3} className={classes.profileImage}>
                  <MediaElement
                    isFluid
                    data={consultant.media}
                    className={classes.cardImage}
                  />
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
              <Box ml={isXs ? 0 : 10} mb={5}>
                <Typography variant="h2">
                  {consultant.name}
                </Typography>
                {consultant.position && (<Typography variant="subtitle1" component="div">
                  {consultant.position}
                </Typography>
                )}
                {consultant.position_2 && (
                  <Typography variant="subtitle1" component="div">
                    {consultant.position_2}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Hidden smDown>
              <Grid item xs={2} style={{ alignSelf: 'flex-start', transform: 'translateY(50px)' }}>
                <Box mb={2} display="flex">
                  <Image
                    isFluid
                    src="/images/immowelt-logo.svg"
                    width="150"
                    height="150"
                  />
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Backdrop>
    </div>
  );
}

function Contact({ classes, consultant }) {
  const items = [];

  if (consultant.email) {
    items.push({
      key: 'email',
      icon: <FiMail/>,
      text: consultant.email,
      href: `mailto:${consultant.email}`,
    });
  }

  if (consultant.phone) {
    items.push({
      key: 'phone',
      icon: <FiPhone/>,
      text: consultant.phone,
      href: `tel:${consultant.phone}`,
    });
  }

  if (consultant.phone2) {
    items.push({
      key: 'phone2',
      icon: <FiPhone/>,
      text: consultant.phone2,
      href: `tel:${consultant.phone2}`,
    });
  }

  items.push({ type: 'divider' });

  if (consultant.action_whatsapp_number) {
    items.push({
      key: 'whatsapp',
      icon: <WhatsappIcon classes={{ icon: classes.whatsappIcon }}/>,
      text: 'WhatsApp',
      href: `https://wa.me/${consultant.action_whatsapp_number}`,
    });
  }

  return (
    <Card className={classes.contactCard}>
      <CardContent>
        <Typography gutterBottom variant="h4">
          <strong>
            Kontakt
          </strong>
        </Typography>
        <List>
          {items.map(item => item.type === 'divider'
            ? (
              <Divider/>
            ) : (
              <ListItem
                key={item.key}
                button
                component="a"
                target="_blank"
                href={item.href}
              >
                <ListItemIcon style={{ minWidth: 32 }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text}/>
              </ListItem>
            ))}
          <Divider/>
          <ListItem
            button
            component="a"
            target="_blank"
            href={`/api/consultant/vcard?id=${consultant.id}`}
          >
            <ListItemIcon style={{ minWidth: 32 }}>
              <FiDownload/>
            </ListItemIcon>
            <ListItemText primary="Download VCF"/>
          </ListItem>
        </List>
        {consultant.action_appointment && (
          <Box mt={1}>
            <Link href={consultant.action_appointment}>
              <Button
                component="a"
                variant="contained"
                target="_blank"
                fullWidth
                color="primary"
              >
                Termin vereinbaren
              </Button>
            </Link>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

function Details({ classes, consultant }) {
  let data = consultant.details;

  if (!data || !data.value || data.value.trim().length === 0) {
    data = {
      id: 0,
      __typename: 'ComponentElementsTypographyElement',
      value: `## Ihre Baufinanzierung mit Best-Zins\n<br>Als Baufinanzierungsvermittler biete ich Ihnen den Vergleich von mehr als ${NUMBER_OF_BANKS} Banken, Sparkassen und Versicherungen und versuche für meine Kunden immer die Bank herauszufinden, die aktuell den besten Zins ausweist und am besten zu Ihnen passt. Meine Beratung ist für Sie kostenfrei und bietet viele Vorteile:\n\n* Kreditzusage schon innerhalb von 2 Tagen\n* Beratung und Einbindung von Fördermitteln und Zuschüssen\n* Finanzierung ohne Eigenkapital bzw. Eigenkapitaloptimiert\n* Persönlicher Ansprechpartner für die gesamte Finanzierung`,
    };
  }

  return (
    <TypographyElement
      data={data}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.quaternary.main,
  },
  header: {
    position: 'relative',
    zIndex: zIndices.consultantHeaderOverlay,
  },
  headerBackground: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 1)), url(/images/consultant-header-01.png)',
  },
  profileImage: {},
  cardImage: {},
  contactCard: {},
  whatsappIcon: {
    width: 14,
    height: 14,
    marginRight: 1,
    marginTop: 1,
  },
  [theme.breakpoints.up('md')]: {
    profileImage: {
      transform: 'translateY(50px)',
    },
  },
}));

Consultant.propTypes = {
  consultant: PropTypes.object,
};
Consultant.getInitialProps = async ({ query, res, graphQlClient }) => {
  const { slug } = query;

  try {
    const { data, error } = await graphQlClient.query({
      query: FETACH_DATA,
      variables: { slug },
    });

    if (error) {
      throw error;
    }

    const employee = data.employees && data.employees.length > 0
      ? data.employees[0]
      : null;

    if (!employee && res) {
      res.statusCode = 404;
    } else if (!employee.published_at && res) {
      res.statusCode = 410;
    }

    if (res && data.siteConfig.is_cache_enabled) {
      res.setHeader('Cache-Control', 'public, max-age=3600');
    }

    return {
      slug,
      consultant: employee,
    };
  } catch (error) {
    if (error.networkError?.result?.errors) {
      console.warn(JSON.stringify(error.networkError?.result?.errors, null, 2));
    }

    throw error;
  }
};

const FETACH_DATA = gql`
  query Data($slug: String!) {
    employees(where: {
      slug: $slug,
      isProfilePageAccessible: true
    }) {
      id
      published_at
      slug
      name
      position
      position_2
      email
      phone
      phone2
      action_whatsapp_number
      action_appointment
      hubspot_user_id
      isFinancingRequestFormVisible
      media {
        ${MediaElement.graphQlSchema}
      }
      details {
        ${TypographyElement.graphQlSchema}
      }
      hub_spot_instance {
        instanceId
      }
      deal_assignee {
        id
        hubspot_user_id
        hub_spot_instance {
          instanceId
        }
      }
    }
    siteConfig {
      is_cache_enabled
    }
  }
`;
